import { IonButton, IonButtons, IonContent, IonFooter, IonImg, IonItem, IonList, IonListHeader, IonPage, IonSlide, IonSlides, IonToolbar, popoverController, } from '@ionic/vue';
import { defineComponent, h } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { isAndroid, isOnlineRef } from '../services/platform';
import { isDark } from '../services/theme';
import { login } from '../utils/oidc';
import env from '../utils/env';
const popOverComponent = defineComponent({
    setup() {
        const router = useRouter();
        async function goto(url) {
            await (await popoverController.getTop()).dismiss();
            router.push(url);
        }
        return () => h(IonContent, {}, () => [
            h(IonList, {}, () => [
                h(IonListHeader, {}, () => [
                    'Rechtliches',
                ]),
                h(IonItem, { onClick: () => goto('/impress') }, () => 'Impressum'),
                h(IonItem, { onClick: () => goto('/agb') }, () => 'AGB'),
                h(IonItem, { onClick: () => goto('/customerinformation') }, () => 'Kundeninformationen'),
                h(IonItem, { onClick: () => goto('/privacy') }, () => 'Datenschutz'),
                h(IonItem, { onClick: () => goto('/licenses') }, () => 'Lizenzen'),
            ]),
        ]);
    }
});
export default defineComponent({
    components: {
        IonButton,
        IonButtons,
        IonContent,
        IonFooter,
        IonImg,
        IonPage,
        IonSlide,
        IonSlides,
        IonToolbar,
    },
    setup() {
        function handleLoginBtnClick() {
            login();
        }
        async function handleRedirectToLegal(ev) {
            const popOver = await popoverController.create({
                component: popOverComponent,
                translucent: true,
                event: ev
            });
            await popOver.present();
        }
        const { t } = useI18n();
        return {
            env,
            isAndroid,
            isDark,
            isOnlineRef,
            handleLoginBtnClick,
            handleRedirectToLegal,
            t,
        };
    },
});
