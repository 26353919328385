import { ref } from 'vue';
import { getMode } from '@ionic/core';
export const isAndroid = getMode() === 'md';
export const isOnlineRef = ref(window.navigator.onLine);
window.addEventListener('online', () => {
    isOnlineRef.value = true;
});
window.addEventListener('offline', () => {
    isOnlineRef.value = false;
});
